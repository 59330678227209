import React from "react";
import {Helmet} from "react-helmet";
import "./dentalimplants.css";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import WhatDentist from "../../../Assets/what.png";
import DentistType from "../../../Assets/dentisttype.jpg";
import multi_implant from "../../../Assets/implant-multi.jpg";
import full_implant from "../../../Assets/full-implant.png";
import Accordion from 'react-bootstrap/Accordion';


export default function DentalImplants(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <React.Fragment>
            <Helmet>
                <title>Dental Implants in Sanpada | Full Mouth Dental Implants | Tooth Replacement in Navi Mumbai  | Panikar's Dentistry</title>
                <meta name="description" content="Looking for a reliable dental implants clinic in Navi Mumbai? Our expert team specializes in full mouth implants and effective tooth replacement solutions in Sanpada, Vashi. Achieve a confident smile today!" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Dental Implants</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>Dental Implants</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistimg">
                                    <img src={WhatDentist} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistcontent">
                                    <h2>What are Dental Implants?</h2>
                                    <p>A dental implant is a titanium screw-like device that is surgically placed into the jawbone, acting as a replacement for the root of a missing tooth. Over time, the implant fuses with the bone in a process called osseointegration, creating a stable foundation for a custom-made dental crown, bridge, or denture.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Types of Dental Implants we offer</h2>
                            <p>Custom Dental Implants for every smile - single, multiple, or full mouth solutions tailored to restore your confidence and oral health.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={DentistType} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Single Tooth Implant</h3>
                                    <p>Single tooth implants involve placing one implant and a crown to replace a single missing tooth. This solution not only fills the gap but also preserves the health of adjacent teeth and prevents bone loss.</p>
                                    <p><b>Benefits:</b> Natural appearance, long-lasting, prevents bone deterioration, and no impact on surrounding teeth.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Multiple Tooth Implants</h3>
                                    <p>For patients missing several teeth in one area, multiple tooth implants are a reliable choice. This treatment involves placing two or more implants to support a bridge that spans the gap. Unlike traditional bridges, which require support from surrounding teeth, multiple tooth implants anchor directly into the jaw, preserving the health of adjacent teeth and promoting jawbone stability.</p>
                                    <p><b>Benefits:</b> Maintains natural tooth alignment, durable and stable, prevents jawbone loss, reduces strain on neighboring teeth, and provides a seamless, natural look.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={multi_implant} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={full_implant} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Full Mouth Implants</h3>
                                    <p>Full mouth implants are an ideal solution for patients who have lost most or all of their teeth. This approach uses multiple implants strategically placed throughout the jaw to support a full arch of prosthetic teeth. Full mouth implants restore functionality, aesthetics, and oral health comprehensively, transforming your smile and improving your quality of life.</p>
                                    <p><b>Benefits:</b> Complete smile restoration, enhanced chewing and speech abilities, supports jaw structure, prevents further bone loss, and offers a secure, natural-looking solution for total tooth replacement.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Benefits of Dental Implants</h3>
                                    <ul>
                                        <li><b>Natural Look and Feel:</b> Implants are designed to blend seamlessly with your natural teeth, providing a confident smile and comfort. No more embarrassing moments when your friends or family spot that false tooth in your mouth and start a discussion over it.</li>
                                        <li><b>Improved Functionality:</b> Unlike traditional dentures, implants do not shift or move, allowing you to speak, chew, and eat with ease. Now you can smile extra wide or laugh your heart out without fear of any embarrassment. And you can eat what you have always wanted to and stay happy and worry free.</li>
                                        <li><b>Preserves Jawbone:</b> By replacing the tooth root, implants help prevent bone loss, which can occur after a tooth is lost. Combined with the occasionally needed strategic reconstructions of gums and bone, this leads to a Time Machine effect where we rebuild all that was lost, including bone and gums, as well as teeth obviously.</li>
                                        <li><b>Durable and Long-Lasting:</b> With proper care, dental implants can last for a lifetime, making them a cost-effective long-term solution. This care usually includes regular check ups and cleaning. Similar to any natural tooth, the crown/bridge/denture fixed to the implant may need repair/replacement or any other maintenance due to daily use related wear and tear.</li>
                                        <li><b>Maintains Facial Structure:</b> Implants support facial structures like lips and cheeks by preventing the sunken appearance that can result from missing teeth. A youthful appearance can be preserved for a longer time than what was once inevitable.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>The Dental Implant Process</h3>
                                    <ul>
                                        <li><b>Consultation:</b> During your first visit, we’ll comprehensively assess your oral health, bone and gums, and determine if you’re a good candidate for dental implants.</li>
                                        <li><b>Implant Placement:</b> In a minor surgical procedure, the titanium implant is placed into the jawbone. This step is performed under local anaesthesia for your comfort.</li>
                                        <li><b>Healing Period:</b> The implant site is left to heal for 1-6 months as the bone integrates with the implant.</li>
                                        <li><b>Abutment Placement:</b> Once healed, a small post (abutment) is attached to the implant, which will support the new tooth.</li>
                                        <li><b>Crown Placement:</b> A custom dental crown is fitted to the abutment, completing the process and restoring your smile.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>FAQ’s</h2>
                            <p>Everything you need to know</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are dental implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental implants are artificial tooth roots made of titanium, used to support replacement teeth like crowns or bridges,
                                        mimicking natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Are Dental Implants Right for You?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Ideal candidates for dental implants have healthy gums, sufficient bone density, and are committed to maintaining good oral hygiene. Even if you don’t come across an ideal candidate, do not worry, our expert team can plan and execute complex reconstructions very successfully, for the most advanced of disease/destruction levels. If you’re still unsure if implants are right for you, our experienced team will evaluate your specific case and recommend the best treatment plan for your needs. Reach out to our <Link to="https://wa.me/+919004366342/" target="_blank">front desk</Link> to know more for a FREE implant suitability assessment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Why Choose Us for Your Dental Implants?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Our use of advanced technology and highest quality materials with the most skilful of minds and hands, ensure a safe and successful implant procedure. Our expert dental implant team of surgeons, nurses and lab technicians provide personalised care and achieving the best possible results across all parameters for every patient. Patient satisfaction is our top priority.  <Link to="/contact-us/" >Call us</Link> to know more about our flexible payment policies for dental implant packages.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
