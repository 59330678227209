import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import nervouspatients from "../../../Assets/nervouspatients.png";
import Accordion from 'react-bootstrap/Accordion';


export default function SmileEnhancement(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <React.Fragment>
            <Helmet>
                <title>Sedation Dentistry in Navi Mumbai | Pediatric Sedation Dentistry in Sanpada | Panikar's Dentistry</title>
                <meta name="description" content="Discover effective sedation dentistry options in Navi Mumbai and Sanpada. Our skilled team ensures a comfortable and anxiety-free dental experience, making dental care accessible for everyone." />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Nervous Patients</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>Nervous Patients</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistimg">
                                    <img src={nervouspatients} alt="Sedation Dentistry in Navi Mumbai" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistcontent">
                                    <h2>What is Sedation Dentistry?</h2>
                                    <p>Sedation dentistry involves the use of oral or inhalation medication to help patients relax during dental procedures. It is an excellent option for patients with dental anxiety, a strong gag reflex, or those who need extensive work done in a single appointment.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Nervous Patients</h2>
                            <p>We understand that some patients, kids and grown ups alike, may feel anxious or uncomfortable about dental procedures. To ensure your comfort and safety, we offer dental treatment under sedation and general anaesthesia options tailored to your specific needs.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>We offer several levels of sedation: </h3>
                                    <ul>
                                        <li><b>Minimal Sedation:</b> You’ll remain awake but relaxed, perfect for shorter or less invasive procedures.</li>
                                        <li><b>Moderate Sedation (Conscious Sedation):</b> You may not remember much of the procedure, but you’ll still be able to respond to the dentist.</li>
                                        <li><b>Deep Sedation:</b> You will be on the edge of consciousness but can still be awakened if needed.</li>
                                        <li><b>General Anaesthesia:</b> For more complex procedures or patients with severe anxiety, general anaesthesia ensures you remain completely unconscious and unaware during treatment.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Is Sedation Right for You?</h3>
                                    <p>Sedation dentistry is particularly helpful if you:</p>
                                    <ul>
                                        <li>Experience significant dental anxiety or fear.</li>
                                        <li>Have a low pain threshold.</li>
                                        <li>Have a strong gag reflex.</li>
                                        <li>Require a large amount of dental work in a single session.</li>
                                        <li>Have difficulty sitting still for long periods.</li>
                                    </ul>
                                    <p>Our dentists will conduct a thorough consultation to determine the most appropriate sedation option based on your medical history, treatment needs, and personal preferences.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>General Anaesthesia for Complex Dental Procedures</h3>
                                    <p>For more complex procedures, such as wisdom tooth removal or extensive oral surgery, non pharmacological behaviour management in children and persons with disabilities, general anaesthesia may be recommended. This type of anaesthesia ensures you are fully asleep, so you won’t feel or remember the procedure. General anaesthesia is administered under the supervision of a qualified anaesthetist to ensure your total safety throughout the process.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Safety and Comfort</h3>
                                    <p>Your safety and well-being are our top priorities. Our team is highly trained and experienced in sedation techniques and general anaesthesia. We carefully monitor all patients throughout their procedure to ensure the highest level of care. Additionally, we provide detailed pre- and post-operative instructions to ensure a smooth and comfortable recovery.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>What to Expect Before, During, and After Sedation or General Anaesthesia?</h3>
                                    <p>Before your procedure, we will provide you with a thorough consultation and instructions on how to prepare. You may need to fast for several hours, depending on the type of sedation or anaesthesia being used. Our team will guide you through every step to ensure a seamless experience.</p>
                                    <p>After your treatment, it’s important to have a trusted companion available to drive you home and assist you, as the effects of sedation or anaesthesia can take some time to fully wear off. We will provide you with aftercare instructions to ensure a comfortable recovery.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Book a Consultation Today</h3>
                                    <p>If you’re considering dental treatment under sedation or general anaesthesia, <Link to="/contact-us/">contact us</Link> today to schedule a consultation. We are committed to providing a relaxed and stress-free dental experience for all our patients.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>FAQ’s</h2>
                            <p>Everything you need to know</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Why Sedation Dentistry?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Sedation dentistry involves the use of oral or inhalation medication to help patients relax during dental procedures. It is an excellent option for patients with dental anxiety, a strong gag reflex, or those who need extensive work done in a single appointment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
