import React, { useState } from "react";
import { BlogList } from "../../Api/blog";
import {Helmet} from "react-helmet";
import "./blogs.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const Blogsettings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: true,
    nextArrow: (
        <button type="button" className="nextslider">
          <FaArrowRightLong style={{ fontSize: '24px' }} />
        </button>
    ),
    prevArrow: (
        <button type="button" className="prevslider">
          <FaArrowLeftLong style={{ fontSize: '24px' }} />
        </button>
    ),
    responsive: [
      {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
          },
      },
      {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
      },
    ]
};
export default function Blogs(){
    const [BlogAllList, setBlogs] = useState([]);
    return(
        <React.Fragment>
            <BlogList onFetch={setBlogs} />
            <Helmet>
                <title>Blog</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Blogs</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="blogpage">
                    <div className="container">
                        <div className="blogpcategory">
                            <ul>
                                <li className="active"><Link to="/blogs/">All</Link></li>
                                <li><Link to="#">General Dentistry</Link></li>
                                <li><Link to="#">Dental Implants</Link></li>
                                <li><Link to="#">Children Dentistry</Link></li>
                                <li><Link to="#">Preventive Dentistry</Link></li>
                            </ul>
                        </div>
                        <div className="bloglistings">
                            <Sliders {...Blogsettings}>
                                {BlogAllList.map((blog) => (
                                    <div className="bloggrid" key={blog.id}>
                                        <div className="blogimg">
                                            <Link to={'/blogs/'+blog.url+'/'}><img src={blog.image} alt={blog.title} /></Link>
                                        </div>
                                        <div className="blogcategory">
                                            <Link to={'/blogs/'+blog.url+'/'}>{blog.cateogry}</Link>
                                        </div>
                                        <div className="blogtitle">
                                            <h2><Link to={'/blogs/'+blog.url+'/'}>{blog.title}</Link></h2>
                                        </div>
                                        <div className="blogcontent">
                                            <p dangerouslySetInnerHTML={{ __html: blog.content || '' }}></p>
                                        </div>
                                    </div>
                                ))}
                            </Sliders>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}