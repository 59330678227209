import React from "react";
import {Helmet} from "react-helmet";
import "./home.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Treatments from "../../Components/Treatments/treatments";
import Testimonials from "../../Components/Testimonials/testimonials";
import Bannerimg from "../../Assets/bannerimage.png";
import { FaArrowRightLong } from "react-icons/fa6";
import Hygiene from "../../Assets/hygienestand.svg";
import Treatment from "../../Assets/treatmentcare.svg";
import Smile from "../../Assets/googlesmile.svg";
import Google from "../../Assets/googlerate.png";
import Drsneha from "../../Assets/drsneha.jpg";
import Sreekumar from "../../Assets/Sreekumar.jpg";

export default function Home(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <React.Fragment>
            <Helmet>
                <title>Best Dentist in Sanpada, Juinagar, Vashi, Turbhe, Navi Mumbai | Panikars Dentistry</title>
                <meta name="description" content="Best dentist in Sanpada, Juinagar, Vashi, and Turbhe. Visit Dr Panikars dental clinic in Navi Mumbai for expert care, advanced treatments, and personalized dental solutions." />
                <meta name="keywords" content="Best Dentist Sanpada, Dental Care Sanpada, Top Dentist in Navi Mumbai,Dental Implants Navi Mumbai" />
            </Helmet>
            <Header/>
            <section className="bannerarea">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-7">
                            <div className="bannercontent">
                                <h2>Where Your Brightest Smile Begins</h2>
                                <p>Let us help you achieve a healthy and beautiful smile that you truly deserve. Give us a call to know more.</p>
                                <div className="bannerbtns">
                                    <Link to="#" onClick={() => scrollToSection('appointment')}>request appointment <FaArrowRightLong/></Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="bannerimagearea">
                                <div className="bannerimg">
                                    <img src={Bannerimg} alt="bannerimg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trustse">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="trustgrid">
                                <div className="tursticon"><img src={Hygiene} alt="Exceptional Hygiene Standards" /></div>
                                <div className="trustcontent">
                                    <h4>Exceptional Hygiene Standards</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="trustgrid">
                            <div className="tursticon"><img src={Treatment} alt="Personalised Treatment & Care" /></div>
                                <div className="trustcontent">
                                    <h4>Personalised Treatment & Care</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="trustgrid">
                            <div className="tursticon"><img src={Smile} alt="Google smile" /></div>
                                <div className="trustcontent">
                                    <img src={Google} alt="Google Rating" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Treatments/>
            <Testimonials/>
            <section className="qualified">
                <div className="container">
                    <div className="doctorheaing">
                        <h2>Our Highly Qualified Dentist's</h2>
                    </div>
                </div>
                <div className="container">
                    <div className="teamdhome">
                        <div className="row align-items-center justify-content-center g-0">
                            <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-12">
                                <div className="doctorimg">
                                    <img src={Drsneha} alt="Dr Sneha Bharuka Panikar" />
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-8 col-sm-12 col-xs-12 col-12">
                                <div className="doctorcontent">
                                    <h2>Dr Sneha Bharuka Panikar</h2>
                                    <small>Practice Owner</small>
                                    <p>Dr Sneha did her post graduation in the specialty field of Paediatric and Preventive Dentistry, batch of 2013, from the prestigious Rajiv Gandhi university of Health Sciences and has since been the practice owner and the primary pedodontist, at Panikar’s Centre for Advanced Dentistry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center g-0">
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="doctorimg">
                                <img src={Sreekumar} alt="Dr Sreekumar Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-8 col-sm-12 col-xs-12 col-12">
                            <div className="doctorcontent">
                                <h2>Dr Sreekumar Panikar</h2>
                                <small>Principal Dentist</small>
                                <p>Sree started his journey in the private practice of dentistry in the year 2008, after he qualified with honours as a dentist from the prestigious Maharashtra University of Health Sciences. Sree is well known for his in-depth knowledge, expert clinical skills and high standards of patient care.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
