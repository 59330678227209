import { useEffect } from "react";
import axios from "axios";
import { Apis } from './api_url';

const BlogList = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(Apis.BlogList, {
                    headers: { 'Authorization': apiKey}
                });
                onFetch(response.data.blogs);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchBlogs();
    }, [apiKey, onFetch]);

    return null;
}
const BlogDetails = ({ currentpageurl, onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        if (currentpageurl) {
            const fetchBlogMeta = async () => {
                try {
                    const response = await axios.post(Apis.BlogDetails, { url: currentpageurl }, {
                        headers: { 'Authorization': apiKey, 'Content-Type': 'application/json' }
                    });
                    if (onFetch) {
                        onFetch(response.data.blog);
                    }
                } catch (error) {
                    console.error('Error fetching page metadata:', error);
                }
            };
            fetchBlogMeta();
        }
    }, [currentpageurl, apiKey, onFetch]);
    return null;
};
const BlogLimited = ({ onFetch }) => {
    const apiKey = process.env.REACT_APP_API_KEY;
    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(Apis.LimitedList, {
                    headers: { 'Authorization': apiKey}
                });
                onFetch(response.data.blogs);
            } catch (error) {
                console.error(error);
                onFetch([], false);
            }
        };
        fetchBlogs();
    }, [apiKey, onFetch]);

    return null;
}
export { BlogList, BlogDetails, BlogLimited };