import React, { useState } from "react";
import { BlogLimited } from "../../Api/blog";
import { Link } from "react-router-dom";
import "./blog.css";

export default function Blogs(){
    const [BlogAllList, setBlogs] = useState([]);
    return(
        <React.Fragment>
            <BlogLimited onFetch={setBlogs} />
            <section className="bloglist">
                <div className="container">
                    <div className="bloghead">
                        <h2>Blogs</h2>
                        <p>Explore our latest insights, expert tips, and updates in dental care.</p> <p>From innovative treatments to oral health advice, our blog keeps you informed and empowered on your journey to a healthier smile.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {BlogAllList.map((blog) => (
                            <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="bloggrid">
                                    <div className="blogimg">
                                        <Link to={'/blogs/'+blog.url+'/'}><img src={blog.image} alt={blog.title} /></Link>
                                    </div>
                                    <div className="blogcategory">
                                        <Link to={'/blogs/'+blog.url+'/'}>{blog.cateogry}</Link>
                                    </div>
                                    <div className="blogtitle">
                                        <h2><Link to={'/blogs/'+blog.url+'/'}>{blog.title}</Link></h2>
                                    </div>
                                    <div className="blogcontent">
                                        <p dangerouslySetInnerHTML={{ __html: blog.content || '' }}></p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
