
import Gallery1 from "../../Assets/gallerybefore.jpg"
import Gallery2 from "../../Assets/galleryafter.jpg"
import bhave_before from "../../Assets/bhave-before.jpg"
import bhave_after from "../../Assets/bhave-after.jpg"
import veneer_before from "../../Assets/Veneers-before.jpg"
import veneer_after from "../../Assets/Veneers-after.jpg"
import fmrafter from "../../Assets/fmr-after.jpg"
import fmrbefore from "../../Assets/fmr-before.jpg"

export const Gallery = [
    { id: '1',galleryheading: 'Invisalign', galleryafter:Gallery2, gallerybefore:Gallery1 },
    { id: '2',galleryheading: 'Full Mouth Rehabilitation', galleryafter:fmrafter, gallerybefore:fmrbefore },
    { id: '3',galleryheading: 'Dental Veneers', galleryafter:veneer_after, gallerybefore:veneer_before },
    { id: '4',galleryheading: 'Full Mouth Rehabilitation', galleryafter:bhave_after, gallerybefore:bhave_before },
]