import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import WhatDentist from "../../../Assets/what.png";

const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
    }
};

export default function SmileEnhancement() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Childrens Dental Care in Sanpada | Best Pediatric Dentist in Juinagar | Panikar's Dentistry</title>
                <meta name="description" content="At Panikar's Dentistry, we specialize in children's dental care, providing a friendly and comfortable environment. As the best dentist for kids in Navi Mumbai, we ensure your child's dental health and smile are our top priorities." />
                <meta name="keywords" content="" />
            </Helmet>
            <Header />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadhead">
                        <h1>Children Dentistry</h1>
                    </div>
                    <div className="breadcrumblist">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="#">Treatments</Link></li>
                            <li>Children Dentistry</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="whatdentist">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="whatdentistimg">
                                <img src={WhatDentist} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                            <div className="whatdentistcontent">
                                <h2>Children & Adolescent Dental Care</h2>
                                <p>“At Panikar’s Centre, we understand that early dental care is crucial for setting your child up for a lifetime of healthy smiles. Our team is specially trained to provide gentle, compassionate care tailored to children and adolescents. From baby teeth to braces for teenagers, we focus on prevention, education, and treatments designed to meet the unique needs of young patients.”, says Dr Sneha, our practice owner and primary paediatric dentist.</p>
                                <div className="whatdentistbtn">
                                    <Link to="#" onClick={() => scrollToSection('appointment')}>Book An Appointment</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="dentisttype">
                <div className="container">
                    <div className="dentisthead">
                        <h2>Why Early Dental Care Matters </h2>
                        <p>Starting dental visits at a young age helps your child become comfortable with the dentist, reduces the risk of cavities, and allows us to catch any potential issues early on. Regular check-ups also help teach your child the importance of oral hygiene and the habits that will last a lifetime.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="dentisttypecontent">
                                <h3>Our Approach to Pediatric Dentistry </h3>
                                <p>Our expert team takes extra steps to make sure each visit is fun, comfortable, and stress-free for your child. Here’s what you can expect:</p>
                                <ul>
                                    <li><b>Kid-Friendly Atmosphere:</b> Our office is designed to be welcoming and relaxing for kids, with toys, books, and movies to make them feel at ease.</li>
                                    <li><b>Education & Prevention:</b> From exams to cleanings, we use kid-friendly techniques and equipment to ensure your child’s comfort.</li>
                                    <li><b>Gentle and Safe Care:</b> From exams to cleanings, we use kid-friendly techniques and equipment to ensure your child’s comfort.</li>
                                    <li><b>Sedation and GA:</b> For the anxious and <Link to="/nervous-patients/">nervous patients</Link>, we have safe methods to address their fears using modern methods of sedation and general anaesthesia.</li>
                                    <li><b>Early Orthodontic Assessment:</b> For older children and adolescents, we monitor tooth and jaw development to identify any potential orthodontic needs early on. This helps us determine if they may need braces or other treatments in the future, and many a times avoid future need for treatment with early intervention.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="dentisttypecontent">
                                <h3>Common Treatments for Children and Adolescents</h3>
                                <ul>
                                    <li><b>Regular Cleanings & Exams:</b> Routine check-ups help maintain healthy teeth and gums while giving us the chance to catch any potential problems early.</li>
                                    <li><b>Fluoride Treatments & Sealants:</b> Fluoride helps strengthen teeth and prevent decay, while sealants provide an extra layer of protection against cavities, especially on the back molars.</li>
                                    <li><b>Fillings & Restorative Care:</b> If cavities occur, we provide gentle, effective treatments to restore the health of your child’s teeth with durable, tooth-colored fillings.</li>
                                    <li><b>Orthodontics:</b> We offer early orthodontic evaluations to determine whether your child may benefit from braces or other corrective treatments to ensure straight, healthy smiles.</li>
                                    <li><b>Emergency Dental Care:</b> Accidents happen, and when they do, we’re here to help with prompt, gentle care for dental emergencies like chipped or knocked-out teeth.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="dentisttypecontent">
                                <h3>Tips for Parents</h3>
                                <ul>
                                    <li>Bring your child in for their first dental visit by their first birthday or when their first tooth appears.</li>
                                    <li>Encourage healthy brushing and flossing habits at home. We recommend supervised brushing until around age 8.</li>
                                    <li>Limit sugary snacks and drinks, which can lead to cavities.</li>
                                    <li>Make dental visits a positive experience by talking about it in a fun, friendly way and avoiding any negative language.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="dentisttypecontent">
                                <h3>Ready to Book an Appointment?</h3>
                                <p>We’re here to help your child smile bright! <Link to="/contact-us/">Contact Us</Link> today to schedule an appointment, and let us take care of their dental health from childhood through adolescence, going forward.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}
