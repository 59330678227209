import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import WhatDentist from "../../../Assets/what.png";


export default function SmileEnhancement(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <React.Fragment>
            <Helmet>
                <title>General Dentistry in Navi Mumbai | Comprehensive Dental Services | Panikar's Dentistry</title>
                <meta name="description" content="Explore our general dentistry services in Navi Mumbai, offering a wide range of general dental services including expert root canal treatments. Your oral health is our priority!" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>General Dentistry</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>General Dentistry</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistimg">
                                    <img src={WhatDentist} alt="General Dentistry" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistcontent">
                                    <h2>What is General Dentistry?</h2>
                                    <p>General dentistry is the foundation of oral healthcare, focusing on the prevention, diagnosis, and treatment of common dental issues. General dentists provide routine care such as dental cleanings, exams, and fillings to maintain oral health and prevent disease. They also address more complex problems like tooth decay, gum disease, and damaged teeth, offering treatments such as root canals, extractions, and crowns. Preventive services like fluoride treatments and sealants help protect teeth from cavities, while oral cancer screenings aid in early detection of potentially serious conditions.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Why General Dentistry?</h2>
                            <p>General dentists provide solutions for tooth replacement through dentures, bridges, or implants, ensuring functional and aesthetic restoration.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Dental Fillings</h3>
                                    <p>Tooth decay is a problem commonly experienced, even in those who stick to regular brushing and flossing. When your teeth are affected by bacteria, sugary drinks or lack thorough cleaning, cavities are inevitable. They can happen at any age, even during infancy. If left untreated, a cavity can affect your tooth all the way down to the root and lead to eventual tooth loss.</p>
                                    <p><b>The Signs of Tooth Decay -</b> If you think you might have a cavity, there are some common symptoms you may experience: Black, brown or white staining on teeth, Holes and pits in teeth, Pain when biting, Pain when eating or drinking hot, cold or sweet substances, Sensitive teeth, Toothache </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Protecting Your Pearly Whites</h3>
                                    <p>Along with healthy oral hygiene habits, regular checkups at our Centre are the best way for you to beat cavities. When they happen, though, we can fill your cavity with a tooth-colored filling that seamlessly matches your smile, chew and live life.</p>
                                    <p>Silver fillings used to be the standard in dental care. As these old-school amalgam fillings contain about 50% mercury, they could pose a risk to your health. Today, safer materials are used that blend in perfectly with the rest of your teeth, so nobody will ever know you have a filling, even you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>The process of getting a Filling</h3>
                                    <p>Your dentist will prepare your tooth for the filling by removing decay with a dental drill. Rest assured that the area surrounding the tooth will be numbed in advance using a local anesthetic. If the decay is located near the root, your dentist will ensure that the nerve is protected by placing a liner made of composite resin or glass ionomer. Next, the filling will be placed then polished. That’s it!</p>
                                    <p>Get your smile the attention it deserves. <Link to="/contact-us/">contact us</Link> today to know more about our services for dental decay treatment.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="dentisthead">
                            <h2>Tooth Extraction Services</h2>
                            <p>Our Centre offers a full range of tooth extraction, using advanced techniques to ensure the safest and most comfortable experience. Whether you’re dealing with impacted teeth, decayed roots, or require specialized procedures, we have the expertise to address your needs.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Disimpaction </h3>
                                    <p>For impacted teeth, such as wisdom teeth that haven’t fully erupted, we provide disimpaction services. This process involves carefully exposing and removing the tooth to prevent complications like infection, pain, or crowding. We ensure a smooth, comfortable experience with minimal recovery time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Surgical Extractions</h3>
                                    <p>For teeth that are broken, severely decayed, or have complex root structures, surgical extraction may be necessary. Our team uses advanced tools and techniques to safely remove the tooth while minimizing trauma to surrounding tissues, promoting faster healing.</p>
                                    <p>Silver fillings used to be the standard in dental care. As these old-school amalgam fillings contain about 50% mercury, they could pose a risk to your health. Today, safer materials are used that blend in perfectly with the rest of your teeth, so nobody will ever know you have a filling, even you.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Apicoectomy</h3>
                                    <p>When a root canal alone isn’t sufficient to resolve an infection, an apicoectomy helps in improving the outcome of the process by eliminating the focal point of the infection. This surgical procedure removes the tip of the tooth’s root, along with the surrounding infected tissue, while saving the rest of the tooth. It’s a great option for preventing the need for a full extraction.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Partial extraction therapy</h3>
                                    <p>For patients indicated to maintain the natural tooth root structure, socket shield and root submergence techniques are ideal. These advanced procedures protect the surrounding bone and gum tissue by preserving a portion of the tooth root, improving aesthetics and supporting future dental implants.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Modern Extraction Techniques</h3>
                                    <p>We also offer cutting-edge methods such as laser-assisted extractions, which reduce bleeding, swelling, and recovery time. These modern techniques ensure precision and comfort during your procedure. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Post-Extraction Care</h3>
                                    <p>Following any extraction, we provide detailed post-care instructions to promote quick and successful healing. We also schedule follow-up visits to ensure your recovery is progressing smoothly without any worries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Root Canal Treatment</h2>
                            <p>Experiencing an abscessed tooth often means dealing with throbbing pain, swollen gums, and likely a trip to the dentist for a root canal. Once associated with discomfort, modern root canal therapy is now a much gentler procedure, comparable to getting a filling. Its purpose is to relieve pain by removing diseased nerve tissue inside the tooth, restoring it to functionality.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>What’s the Procedure?</h3>
                                    <p>For severe infections, your dentist may prescribe antibiotics before treatment. During the root canal, a local anesthetic is administered, and the infected nerve tissue is removed. The tooth’s root is then treated with an antibiotic and filled to eliminate remaining infection. Finally, the tooth is protected with a crown, as infection may weaken it. The procedure often requires multiple visits.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <div className="dentisttypecontent">
                                    <h3>Experiencing Pain?</h3>
                                    <p>If you’re suffering from moderate to severe tooth pain with gum swelling, contact us immediately for an appointment. Prompt treatment is crucial, as delays can lead to worsening infection, potential tooth loss, and more extensive care.</p>
                                    <p>At Panikar’s Centre for Advanced Dentistry, we specialize in pain-free root canal therapy. Don’t let fear delay your treatment—we’re here to help you feel better fast!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
