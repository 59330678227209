import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import Bannerimg from "../../../Assets/bannerimage.png";
import Accordion from 'react-bootstrap/Accordion';
import whitening from "../../../Assets/teeth-whitening.png";
import veneers from "../../../Assets/Veneers-after.jpg";
import gum from "../../../Assets/blog.jpg";
import veneer_before from "../../../Assets/Veneers-before.jpg"
import veneer_after from "../../../Assets/Veneers-after.jpg"

export default function SmileEnhancement(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    return(
        <React.Fragment>
            <Helmet>
                <title>Teeth Whitening in Sanpada | Cosmetic Smile Enhancement in Juinagar, Vashi | Panikar's Dentistry</title>
                <meta name="description" content="Achieve a brighter smile with our professional teeth whitening services in Navi Mumbai. Specializing in smile enhancement and offering the top teeth whitening solutions in Vashi. Transform your smile today!" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Smile Enhancement</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>Smile Enhancement</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistimg">
                                    <img src={Bannerimg} alt="Smile Enhancement" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistcontent">
                                    <h2>Smile Enhancement</h2>
                                    <p>A bright, white smile can enhance your appearance and boost your confidence. We offer professional teeth whitening treatments to help you achieve the smile you’ve always wanted. Whether you’re looking for immediate results with our in-office bleaching or prefer the convenience of an at-home solution, we have options tailored to meet your needs.</p>
                                    <p>Improves your smile through teeth whitening, crowns, bridges, and creating a brighter, natural-looking appearance.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Types of Smile Enhancement we offer</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={veneers} alt="Dental Veneers sanpada" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Dental Veneers</h3>
                                    <p>We believe that everyone deserves a smile they can be proud of. Dental veneers are one of the most popular, effective and safe solutions for transforming your smile and giving you a confident, radiant appearance. </p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                <h3>Teeth Whitening</h3>
                                    <p>A bright, white smile can enhance your appearance and boost your confidence. We offer professional teeth whitening treatments to help you achieve the smile you’ve always wanted. Whether you’re looking for immediate results with our in-office bleaching or prefer the convenience of an at-home solution, we have options tailored to meet your needs.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={whitening} alt="Teeth Whitening" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={gum} alt="Gum Treatment in vashi" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Gum Treatment</h3>
                                    <p>At our Centre, we offer comprehensive care for gum health and aesthetics, addressing everything from mild gingivitis to advanced periodontal disease. Using both conventional and modern techniques, we tailor each treatment to meet your unique needs, for best possible results.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>Dental Veneers FAQ’s</h2>
                            <p>Everything you need to know about Dental Veneers.</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are dental veneers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Dental veneers are thin, custom-made shells designed to cover the front surface of your teeth. Made from high-quality porcelain or ceramic, veneers are bonded to your teeth to improve their appearance by adjusting their color, shape, size, or length. They offer a natural-looking solution for various cosmetic concerns.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are the benefits of dental veneers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><b>Enhances Appearance: </b>Veneers can correct issues like discolored, chipped, cracked, or worn-down teeth, as well as minor alignment problems</p>
                                        <p><b>Long-lasting Results: </b>With proper care, veneers can last 10-15 years. We recommend periodic replacement to maintain your smile over time.</p>
                                        <p><b>Natural Look: </b>The materials used mimic the natural translucency of tooth enamel, creating a seamless and natural-looking smile.</p>
                                        <p><b>Quick Transformation: </b>Veneers provide fast results, often achieving a complete smile makeover in just a few visits.</p>
                                        <p><b>Minimally Invasive: </b>Veneers require minimal tooth preparation, making the process comfortable and preserving your natural tooth structure.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How does the veneer process work?</Accordion.Header>
                                    <Accordion.Body>
                                    <p><b>Consultation: </b>During your first consultation, we will evaluate your smile, discuss your goals, and determine if veneers are the right choice for you.</p>
                                        <p><b>Customization: </b>Precise impressions of your teeth are taken, and you’ll collaborate with our team to select the ideal shade and shape for your veneers.</p>
                                        <p><b>Preparation: </b>A thin layer of enamel is removed from your teeth to make space for the veneers. Temporary veneers may be placed while the custom ones are being created. </p>
                                        <p><b>Placement: </b>Once your custom veneers are ready, they are bonded to your teeth, giving you a flawless, natural-looking smile.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How long do dental veneers last?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>With proper care, dental veneers can last between 10 to 15 years. Regular dental check-ups and cleanings will help maintain the appearance and longevity of your veneers.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>Are dental veneers suitable for everyone?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Yes, veneers are a suitable option for most adults, regardless of medical status. The procedure is minimally invasive and preserves the strength and functionality of your natural teeth.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How quickly can I achieve a new smile with veneers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Veneers can deliver fast results, often completing a full smile transformation in just a few visits. With our advanced planning and execution, some patients can achieve a new smile in as little as three days.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>What is the recovery like after getting veneers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>The veneer process is minimally invasive, so recovery is typically smooth and comfortable. Regular check-ups and cleanings will ensure your veneers stay in top condition.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How can I start my journey to a perfect smile with veneers?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>If you're ready to enhance your smile with veneers, contact us today to schedule your consultation. We can help you achieve the beautiful, radiant smile you've always dreamed of!</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2> Teeth Whitening FAQ’s</h2>
                            <p>Everything you need to know about Teeth Whitening</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What is professional teeth whitening?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Teeth whitening is a cosmetic dental procedure that brightens and whitens your teeth, removing stains and discoloration. We offer both in-office and at-home whitening options to help you achieve the smile you've always wanted.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are the benefits of in-office teeth whitening?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><b>Immediate Results: </b>You can see a dramatically whiter smile in just one session.</p>
                                        <p><b>Safe and Comfortable: </b>Our skilled dentists perform the procedure, ensuring your gums and teeth are protected throughout the process.</p>
                                        <p><b>Effective for Stubborn Stains: </b>It’s ideal for treating discoloration caused by coffee, tea, wine, tobacco, gutka, or age.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How does in-office teeth whitening work?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>- Your gums and soft tissues are protected to prevent sensitivity.</p>
                                        <p>- A professional-grade whitening gel is applied to your teeth.</p>
                                        <p>- A specialized light is used to accelerate the whitening process.</p>
                                        <p>- In about an hour, your teeth will be several shades whiter.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What are the benefits of at-home teeth whitening?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><b>Convenience: </b>You can whiten your teeth on your own schedule.</p>
                                        <p><b>Custom-fit Trays: </b>The trays are designed to fit your teeth perfectly for even whitening and maximum comfort.</p>
                                        <p><b>Professional-strength Formula: </b>The whitening gel is stronger and more effective than over-the-counter options.</p>
                                        <p><b>Gentle Whitening: </b>You can control the intensity and duration of your treatment to minimize sensitivity.</p>
                                        <p><b>Touch-ups: </b>You can use the at-home kit to maintain or enhance results after an in-office treatment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How does at-home teeth whitening work?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>- We take impressions of your teeth to create custom trays.</p>
                                        <p>- You receive a professional-grade whitening gel to apply in your trays.</p>
                                        <p>- Wear the trays for the recommended time (usually 30 minutes to 1 hour) each day.</p>
                                        <p>- In about two weeks, you’ll notice a significantly whiter smile.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Which whitening option is best for me?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>During your orthodontic consultation, our specialists will help you select the best treatment option based on your dental needs and aesthetic preferences.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>What can I expect from my orthodontic treatment experience?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><b>In-office whitening </b>is ideal if you want fast, dramatic results, especially for a special occasion.</p>
                                        <p><b>At-home whitening </b>is better if you prefer flexibility and gradual results, or if you want to touch up your teeth after an in-office treatment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How do I get started with teeth whitening?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can schedule a <Link to="/">consultation</Link> with us to discuss your goals and find the best whitening option for your needs.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2> Gum Treatment FAQ’s</h2>
                            <p>Everything you need to know about Gum Treatment</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What types of gum treatments do you offer?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>We offer comprehensive gum treatments, including flap surgery, gum recession treatment, scaling and root planing, laser therapy, and ongoing periodontal maintenance. Each treatment is tailored to your specific needs to improve your gum health and appearance.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What is flap surgery?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Flap surgery is a procedure for advanced periodontal disease. The gums are lifted to access and clean deep pockets of bacteria, plaque, or tartar. This treatment helps reduce gum disease progression and promotes healing.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What is gum recession treatment?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Gum recession treatment addresses exposed tooth roots caused by receding gums. We use grafting techniques, either with your own tissue or biocompatible materials, to restore your gumline, protect your teeth, and reduce sensitivity.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What is scaling and root planing?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Scaling and root planing is a non-surgical deep cleaning method. It removes plaque and tartar from below the gumline, targeting bacteria that cause gum disease. This procedure also smooths the tooth roots to encourage gum reattachment and healing.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How does laser therapy for gums work?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Laser therapy is a modern, less invasive alternative to traditional gum surgery. The laser precisely targets and removes infected tissue, promotes tissue regeneration, and reduces discomfort and recovery time.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>What is periodontal maintenance?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Periodontal maintenance involves ongoing care after your initial gum treatment. It includes specialized cleanings and monitoring to prevent the return of gum disease and keep your gums healthy over time.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>How do I know if I need gum surgery?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Gum surgery is often recommended for advanced cases of periodontal disease, gum recession, or when other non-surgical treatments haven't fully resolved the issue. A consultation with our specialists will help determine the best treatment for you.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How do I get started with gum treatments?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><Link to="/contact-us/">Contact us</Link> today to schedule a consultation. We'll evaluate your gum health and recommend the most suitable treatment to improve your gum condition and maintain a healthy, vibrant smile.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
                <section className="smiling">
                <div className="container">
                    <div className="smilinghead">
                        <h2>Smiling Patients, Happy Hearts</h2>
                        <p>See what our patients have to say about their experience with us. We take pride in creating beautiful smiles and lasting relationships through exceptional care.</p>
                    </div>
                    <div className="container">
                            <div className="smilegallery">
                                <div className="smilegalleryhed">
                                    <h3>Dental Veneers</h3>
                                </div>
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-4">
                                        <img src={veneer_before} alt="Before" />
                                        <p>Before</p>
                                    </div>
                                    <div className="col-lg-4">
                                        <img src={veneer_after} alt="After" />
                                        <p>After</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </React.Fragment>
    )
}
