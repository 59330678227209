import React from "react";
import "./error.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Errorimg from "../../Assets/error-1-img.png";


export default function Error(){
    return(
        <React.Fragment>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Page Not Found</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li>Page Not Found</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="errorpage">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-4">
                                <div className="errorimgs">
                                    <img src={Errorimg} alt="Error" />
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6">
                                <div className="errorcontent">
                                    <h2>Page Not Found</h2>
                                    <p>Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
                                    <div className="errorbtns">
                                        <Link to="/">Back to Home</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}