import React from "react";
import { Link } from "react-router-dom";
import "./treatments.css";
import Dental from "../../Assets/icons/Dental.svg"
import Teeth from "../../Assets/icons/Teeth.svg"
import Mouth from "../../Assets/icons/mouth.svg"
import Smile from "../../Assets/icons/Smile.svg"
import Preventive from "../../Assets/icons/Preventive.svg"
import General from "../../Assets/icons/General.svg"

export default function Treatments(){
    return(
        <React.Fragment>
            <section className="treatments">
                <div className="container">
                    <div className="treatmenthead">
                        <h2>Treatments At The Best Dental Clinic By Top Dentists</h2>
                        <p>As the best dentist in Sanpada, we are committed to using only the highest quality materials, ensuring every treatment delivers exceptional results and lasting care for our patients. Your smile deserves nothing less than the best.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={Dental} alt="Dental Implants" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/dental-implants/">Dental Implants</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>A dental implant is an artificial tooth root that’s placed into your jaw to hold a prosthetic tooth or bridge.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/dental-implants/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={Teeth} alt="Teeth Alignment" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/teeth-alignment/">Teeth Alignment</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>Uses braces or clear aligners to straighten teeth and enhance oral health and smile aesthetics.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/teeth-alignment/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={Mouth} alt="Full Mouth Rehabilitation" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/full-mouth-rehabilitation/">Full Mouth Rehabilitation</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>Involves restoring all teeth, improving function and aesthetics, for a complete, healthy, and beautiful smile.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/full-mouth-rehabilitation/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={Smile} alt="Smile enhancement" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/smile-enhancement/">Smile Enhancement</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>Improves your smile through teeth whitening, crowns, bridges, and creating a brighter, natural-looking appearance.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/smile-enhancement/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={Preventive} alt="Children Dentistry" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/children-dentistry/">Children Dentistry</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>Focuses on children's dental care, emphasizing early prevention and treatments for lifelong oral health.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/children-dentistry/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12">
                            <div className="treatmentgrid">
                                <div className="treatementicon">
                                    <img src={General} alt="General Dentistry" />
                                </div>
                                <div className="treatemntheading">
                                    <h2><Link to="/general-dentistry/">General Dentistry</Link></h2>
                                </div>
                                <div className="treatmentcontent">
                                    <p>Covers routine dental care, including cleaning, fillings, extractions, and oral exams, to maintain overall oral health.</p>
                                </div>
                                <div className="treatmentbtns">
                                    <Link to="/general-dentistry/">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
