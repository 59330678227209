import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./smilegallery.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import { Gallery } from "./gallerydata"
import { FaStar } from "react-icons/fa";
import Sliders from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testimonialimg from "../../Assets/testimonialimg.jpg"
import { Modal, Button } from 'react-bootstrap';
import YouTube from 'react-youtube';
import { FaRegCirclePlay } from "react-icons/fa6";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";


const testisetting = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: true,
    nextArrow: (
        <button type="button" className="nextslider">
            <FaArrowRightLong style={{ fontSize: '24px' }} />
        </button>
    ),
    prevArrow: (
        <button type="button" className="prevslider">
            <FaArrowLeftLong style={{ fontSize: '24px' }} />
        </button>
    ),
    responsive: [
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 1,
            },
        },
    ]
};
const Smileslidersettings = {
    dots: false,
    infinite: true,
    speed: 200,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    arrows: true,
    nextArrow: (
        <button type="button" className="nextslider">
            <FaArrowRightLong style={{ fontSize: '24px' }} />
        </button>
    ),
    prevArrow: (
        <button type="button" className="prevslider">
            <FaArrowLeftLong style={{ fontSize: '24px' }} />
        </button>
    )
};
export default function SmileGallery() {
    const [isOpen, setIsOpen] = useState(false);
    const [videoId, setVideoId] = useState('');
    const handleClose = () => setIsOpen(false);
    const openModal = (id) => {
        setVideoId(id);
        setIsOpen(true);
    };
    const closeModal = () => {
        setIsOpen(false);
        setVideoId('');
    };
    const opts = {
        height: '500px',
        width: '100%',
        playerVars: {
            autoplay: 1,
            rel: 0,
        },
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>Visit Our Dental Clinic | Panikar's Dentistry Navi Mumbai</title>
                <meta name="description" content="Discover top-quality dental care at Panikar's Dentistry in Navi Mumbai. Visit us for comprehensive treatments and a friendly, welcoming environment!" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadhead">
                        <h1>Smile Gallery</h1>
                    </div>
                    <div className="breadcrumblist">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Smile Gallery</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="smiling">
                    <div className="container">
                        <div className="smilinghead">
                            <h2>Smiling Patients, Happy Hearts</h2>
                            <p>See what our patients have to say about their experience with us. We take pride in creating beautiful smiles and lasting relationships through exceptional care.</p>
                        </div>
                        <div className="container">
                            <Sliders {...Smileslidersettings}>
                                {Gallery.map((Gallerys) => (
                                <div className="smilegallery" key={Gallerys.id}>
                                    <div className="smilegalleryhed">
                                        <h3>{Gallerys.galleryheading}</h3>
                                    </div>
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-lg-4">
                                            <img src={Gallerys.gallerybefore} alt="Before" />
                                            <p>Before</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <img src={Gallerys.galleryafter} alt="After" />
                                            <p>After</p>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </Sliders>
                        </div>
                    </div>
                </section>            
            <section className="smilingpate">
                <div className="container">
                    <div className="smilingpatehead">
                        <h2>Smiling Patients, Happy Hearts</h2>
                        <p>See what our patients have to say about their experience with us. We take pride in creating beautiful smiles and lasting relationships through exceptional care.</p>
                    </div>
                </div>
                <div className="container">
                    <Sliders {...testisetting}>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Lata Hari</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>Excellent services! What a doctor! Dr Sreekumar and his team have been very helpful in making my complex implant treatment very simplified and super fast. With all the latest and best technology at the disposal of his highly skilled hands, there is nothing he canit do. I'm very fortunate to have
                                    discovered him and this clinic. A million thanks won't be enough!</p>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>PREITY</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>I had a very comfortable experience at the clinic. I visited the clinic for 2 root canals and teeth straightening over 7
                                    months, along with regular follow-ups. Drs. Sreekumar and Sneha were very professional, committing time to explaining procedures and answering my questions. The staff were
                                    humble and supportive during my visits. The clinic was clean and hygienic at all times, and I had no concerns regarding Covid. They are very good value for money.</p>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Shyamsundar</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialvideo">
                                <img src={Testimonialimg} alt="" />
                                <div className="videobtns" onClick={() => openModal('D_R2cGyeV9U')}><FaRegCirclePlay /></div>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Kavita Bokare</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>My child had her root canal done at Panikar Centre. It's a very good clinic where they minutely follow all the SOPS, along with informed consent about the procedures and have set
                                    a benchmark for the quality of treatment and care provided post procedures. Dr. Sneha Panikar is a very experienced and professional pedodontist.</p>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Rashi Bharuka</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>I had visited the clinic on recommendation by my friend for teeth whitening last week. Dr Panikar was very good with his explanation of the process and the whole job was done very smoothly and effortlessly and it was very comfortable. And my teeth got brighter than what I was
                                    expecting. and that too in less than an hour. He is very skilled and has a warm personality, made my fear of the process vanish with his smile and reassuring words He's my go-to dentist now and I would definitely recommend him to all those who want high quality dental care, especially for those who are very concerned with aesthetics and dental beauty.</p>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Mugdha Ojha</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>I recently changed my dentist to Panikaris and could see a huge difference in the procedures and processes done here as compared to my previous dentist. It's always better to invest in the right doctor than to
                                    get most of the treatments redone as I had to get. I am satisfied with all the treatments done here. Panikar•s is now our family dentistry!</p>
                            </div>
                        </div>
                        <div className="testimonialsgrids">
                            <div className="testimonialname">
                                <h3>Nitin Unde</h3>
                                {[...Array(5)].map((_, index) => (
                                    <FaStar key={index} />
                                ))}
                            </div>
                            <div className="testimonialcontent">
                                <p>My 14 days old baby was born with natal teeth on the lower jaw, and was unable to breast feed. We got the right treatment here and got the teeth removed . Dr sneha and the staff is very experienced and humble. All covid 19 precautions
                                    measures were taken here. Best dental clinic I have visited till date. The treatment was very smooth and quick. My baby is feeding well now. I would highly recommend this clinic.</p>
                            </div>
                        </div>
                    </Sliders>
                </div>
            </section>
            <Modal show={isOpen} onHide={closeModal} centered size="lg" dialogClassName="video-popup">
                <Modal.Body>
                    <div className="videopopupclose">
                        <Button variant="close" onClick={handleClose} aria-label="Close"></Button>
                    </div>
                    {videoId && <YouTube videoId={videoId} opts={opts} />}
                </Modal.Body>
            </Modal>
            <Footer />
        </React.Fragment>
    )
}