const API_URL = "https://cadmin.panikarsdentistry.com";
// const API_URL = "http://localhost/dance-embassy";
const APP_URL = "https://www.panikarsdentistry.com";

const Apis = {
  BlogList: `${API_URL}/api/all-blogs`,
  BlogDetails: `${API_URL}/api/blog-details`,
  LimitedList: `${API_URL}/api/limited-blogs`,
  ContactForms: `${API_URL}/api/contact-us`,
};
export { API_URL, Apis, APP_URL };