import React from "react";
import {Helmet} from "react-helmet";
import "./whychoose.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Practice from "../../Assets/practice.jpg";
import Sreekumar from "../../Assets/Sreekumar.jpg";
import Sneha from "../../Assets/drsneha.jpg";
import Technologies from "../../Assets/technologies.jpg";
import DrMeenu from "../../Assets/Drmeenu.jpg";
import DrAastha from "../../Assets/Draastha.jpg";

export default function WhyChoose() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Why Choose Panikar's Dentistry? | Exceptional Dental Care</title>
                <meta name="description" content="Learn why Panikar's Dentistry is the best choice for your dental needs. We offer expert care, advanced treatments, and a patient-centered approach!" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadhead">
                        <h1>Why Choose Us</h1>
                    </div>
                    <div className="breadcrumblist">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Why Choose Us</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="practice">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-8">
                            <div className="practiceleft">
                                <div className="practicehed">
                                    <h2>Why Choose Us for Your Dental Care?</h2>
                                </div>
                                <div className="practicecontent">
                                    <h3>Our sole aim is to provide the highest quality dental care in an environment that prioritizes your health, comfort, and satisfaction. Here are some reasons why patients choose us:</h3>
                                        <p><b>Excellent Hygiene & Cleanliness:</b> Your safety is our top priority. We adhere to very stringent protocols of sterilization and infection control, ensuring a clean, hygienic environment for every visit.</p>
                                        <p><b>Highly Skilled Expert Team:</b> Our team of dentists and specialists brings years of experience, advanced training, and a passion for excellence in every procedure, be it a routine checkup or a complex treatment, you’re in truly expert hands. Our <Link to="/smile-gallery/">smile gallery</Link> boasts of the happiest of patients who proudly flaunt the top notch dental service, they have received at the hands of our professionals who are industry opinion leaders, yet look forward to each case with a child’s enthusiasm.</p>
                                        <p><b>Research-based Methodology:</b> Our approach is deep-rooted in the latest research and best practices to provide you with the most effective and reliable care.</p>
                                        <p><b>Advanced Techniques, Technology, and Operating Systems:</b> We value your time. Our streamlined scheduling, treatment planning, and patient communication systems ensure that your visits are smooth, efficient, and stress-free.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="practiceimage">
                                <img src={Practice} alt="Dental practice environment" />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-12">
                            <div className="practicecontent">
                            <p><b>5-Star Patient Reviews:</b> We’re proud of the happy feedback we’ve received for our exceptional care, personalized service, and comfortable patient experience.
                            Choose us for advanced and expert dental care that combines modern knowledge and innovation with a patient-centered approach. Let us help you achieve a healthy and beautiful smile that you truly deserve. Give us a <Link to="tel:+919004366342">call</Link> to know more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="meetourteam">
                <div className="container">
                    <div className="meetteamhead">
                        <h2>Meet our specialists</h2>
                        <p>With years of expertise and dedication, our team of specialists is committed to delivering personalized care tailored to your unique needs.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={Sneha} alt="Dr Sneha Bharuka Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Sneha Bharuka Panikar</h3>
                                <small>Practice Owner</small>
                                <p>Dr Sneha did her post graduation in the specialty field of Paediatric and Preventive Dentistry, batch of 2013, from the prestigious Rajiv Gandhi university of Health Sciences and has since been the practice owner and the primary pedodontist, at Panikar’s Centre for Advanced Dentistry.</p>
                                <p>She mainly specialises in all round dentistry for children and young adults, as well as orthodontic treatments for all age groups. She has a special interest in the practice of clinical orthodontics involving removable and fixed braces, clear aligners and musculoskeletal appliances for growing children as well as adults. She has extensively trained in sedation and <Link to="/general-dentistry/">general anaesthesia in dentistry</Link>, root canal therapy for children. 
                                “At Panikar’s, we always opt for a proven scientific approach to address the problems and demands of all of our patients. This makes us happy and our patients happier”, says Dr Sneha. </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={Sreekumar} alt="Dr Sreekumar Panikar" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Sreekumar Panikar</h3>
                                <small>Principal Dentist</small>
                                <p>Sree started his journey in the private practice of dentistry in the year 2008, after he qualified with honours as a dentist from the prestigious Maharashtra University of Health Sciences.</p>
                                <p>Sree is well known for his in-depth knowledge, expert clinical skills and high standards of patient care. Bespoke, high end procedures like surgeries involving any or all teeth, all kinds of <Link to="/dental-implants/">dental implants</Link>, teeth whitening, Invisalign, lasers and ultra modern digital dentistry are his body of work.</p>
                                <p>In 2010, he founded Panikar’s Centre for Advanced Dentistry. In 2016, he entrusted Dr Sneha with the leadership of the practice and now serves as the principal dentist and face of the practice. </p>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={DrMeenu} alt="Dr Meenu Dinesh" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Meenu Dinesh</h3>
                                <small>Orthodontist</small>
                                <p>Dr Meenu is an orthodontist by way of specialty training, and henceforth has been intensely involved with Panikar’s Centre in specialty practice limited to orthodontics. She completed her graduation in 2016, and post graduate orthodontic training in 2021 and was a top rank holder in the university during her time. She is a highly valued consultant for our highly complex cases and has been very instrumental in collaborating with the resident dentists in providing her expertise and contributing to the best possible solutions.</p>
                                <p>Her focus is only on purely clinical orthodontics where she relies on time tested methods for safe tooth movement and hence is able to promise long term results. She is a certified expert in fixed braces therapy.</p>
                                <p>“Meenu exudes a warm and kind aura, which immediately puts her patients at ease. This immensely helps them on the oft long journey of orthodontic correction."</p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="meetteamimg">
                                <img src={DrAastha} alt="Dr Aastha Dureja" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="meetteamcontent">
                                <h3>Dr Aastha Dureja</h3>
                                <small>Endodontist</small>
                                <p>Dr Aastha is a endodontist with Panikar’s Centre with specialty practice limited to root canals and direct restorative work. She completed her graduation in 2019, and post graduate endodontics training in 2023 and was a top rank holder in the university during her time.</p>
                                <p>Her focus is only on purely clinical endodontics where she relies on time tested methods for root canal therapy and fillings and tooth reconstruction, and hence is able to provide excellent results.</p>
                                <p>“Aastha is somebody who goes by the book. Whether it be simple fillings or root canal therapy for teeth with complex anatomy, she has a confidence that very few possess. She has her own drive for excellence, which keeps us at ease while considering that the job at hand will be done to perfection. Apart from being neat and excellent at her work, she is an honest and genuinely good human being, above all!” says Dr Sneha.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="technologies">
                <div className="container">
                    <div className="technologieshead">
                        <h2>Latest Technology</h2>
                        <p>Thanks to major technological advancements, dentistry allows treating the most complex cases with less time and more efficiency.</p>
                    </div>
                </div>
                <div className="container mt-45">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="technologiesimg">
                                <img src={Technologies} alt="Modern dental technology" />
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="technologiescontent">
                                <h3>The Future of Dentistry is Digital:</h3>
                                <p>Dentists today already utilize software to capture insights in clinical decision-making. These practices will continue to develop to integrate A1 algorithms that enable clinicians to find the best modalities for their patients.</p>
                                <p>In the 21st century, digital radiographs and 3D imaging have become the standard of dental care. Using an intraoral scanner with digitized data for 3D dental impressions vs. polyvinyl siloxane and rubber base impressions for a dental crown is now commonplace.</p>
                                <p>Artificial intelligence is laying the groundwork for the future of the dental industry. Dental robots can now perform functions such as filling cavities and cleaning or extracting teeth</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    );
}
