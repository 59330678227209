import React, {useState} from "react";
import { BlogDetails } from "../../Api/blog";
import {Helmet} from "react-helmet";
import "./blogdetail.css";
import { Link, useLocation } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";

export default function Blogs(){
    const [BlogData, setBlogdetails] = useState({});
    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');
    const secondSegment = pathSegments[1] || '';
    console.log(BlogData)
    return(
        <React.Fragment>
            <BlogDetails currentpageurl={secondSegment} onFetch={setBlogdetails} />
            <Helmet>
                <title>{BlogData.metatitle ? BlogData.metatitle : BlogData.title}</title>
                <meta name="description" content={BlogData.metadescription || ''} />
                <meta name="keywords" content={BlogData.metakeyword || ''} />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea blogdetialsbre">
                    <div className="container">
                        <div className="breadhead">
                            <h1>{BlogData.title}</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/blogs/">Blogs</Link></li>
                                <li>{BlogData.title}</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="blogdetails">
                    <div className="container">
                        <div className="blogdata">
                            <div className="blogdetailsimg">
                                <img src={BlogData.image} alt={BlogData.title} />
                            </div>
                            <div className="blogcontent" dangerouslySetInnerHTML={{ __html: BlogData.content || '' }}></div>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}