import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../Components/Header/header";
import Footer from "../../../Components/Footer/footer";
import aligners from "../../../Assets/aligners-main.png";
import aligner from "../../../Assets/aligners.jpg";
import braces from "../../../Assets/braces.jpg";
import Accordion from 'react-bootstrap/Accordion';


export default function SmileEnhancement(){
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    return(
        <React.Fragment>
            <Helmet>
                <title>Teeth Alignment Service in Sanpada | Teeth Straightening in Juinagar | Panikar's Dentistry</title>
                <meta name="description" content="Achieve the perfect smile with our expert teeth alignment services in Sanpada and Juinagar. Specializing in teeth straightening, we provide personalized solutions for all your dental alignment needs." />
                <meta name="keywords" content="" />
            </Helmet>
            <Header/>
                <section className="breadcrumbarea">
                    <div className="container">
                        <div className="breadhead">
                            <h1>Teeth Alignment</h1>
                        </div>
                        <div className="breadcrumblist">
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="#">Treatments</Link></li>
                                <li>Teeth Alignment</li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section className="whatdentist">
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistimg">
                                    <img src={aligners} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                <div className="whatdentistcontent">
                                    <h2>Teeth Alignment</h2>
                                    <p>Reveal your best smile with our expert Teeth Alignment treatment service. Whether you choose discreet, removable clear aligners or classic braces, each solution is designed to fit your lifestyle and aesthetic goals. </p>
                                    <p>Options include clear aligners for invisible correction, ceramic braces for a subtle look, self-ligating braces for faster results, and lingual braces for ultimate discretion behind the teeth. Our team provides personalized care from start to finish, helping you achieve a healthier, beautifully aligned smile with confidence.</p>
                                    <div className="whatdentistbtn">
                                        <Link to="#" onClick={() => scrollToSection('appointment')}>Book an Appointment</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="dentisttype">
                    <div className="container">
                        <div className="dentisthead">
                            <h2>Types of Teeth Alignment we offer</h2>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={aligner} alt="Clear Aligners" />
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Clear aligners</h3>
                                    <p>We offer cutting-edge clear aligners as a convenient, comfortable and discreet alternative to traditional braces. Our aligners are custom-made to fit your teeth, helping you achieve a straighter, cleaner and a more beautiful smile, that you can be proud of, all without the need for metal brackets or wires.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-7 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypecontent">
                                    <h3>Traditional Braces</h3>
                                    <p>At our clinic, we offer a wide range of orthodontic treatment options, tailored to meet the unique needs of every patient. Whether you’re looking to correct misaligned teeth, close gaps, improve your bite or flash a perfect smile, we provide various braces systems to help you achieve a confident, healthy smile. During your orthodontic consultation, we will help you choose the most suitable treatment plan amongst all the available contemporary options.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12 col-12">
                                <div className="dentisttypeimg">
                                    <img src={braces} alt="Traditional Braces" />
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>Clear Aligners FAQ’s</h2>
                            <p>Everything you need to know about Clear Aligners service</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What are clear aligners?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Clear aligners are transparent, removable dental trays designed to gradually move your teeth into the desired position. Using advanced 3D imaging and planning technology, a series of aligners are custom-made for your teeth. Each set is worn for 1-2 weeks before switching to the next.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are the benefits of clear aligners?</Accordion.Header>
                                    <Accordion.Body>
                                        <p><b>Invisible: </b>The aligners are clear and virtually unnoticeable, allowing you to smile with confidence throughout your treatment.</p>
                                        <p><b>Removable: </b>You can take them out for eating, brushing, and flossing, with no food restrictions or complicated cleaning routines.</p>
                                        <p><b>Comfortable: </b>Without metal wires or brackets, aligners cause less irritation, providing a more comfortable treatment experience.</p>
                                        <p><b>Effective: </b>Clear aligners can treat a wide variety of orthodontic issues, including crowding, gaps, and bite misalignments. Most patients are good candidates for aligner treatment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>How does the clear aligner process work?</Accordion.Header>
                                    <Accordion.Body>
                                    <p><b>Consultation: </b>We assess your teeth, discuss your goals, and determine if clear aligners are the right solution for you.</p>
                                        <p><b>Customized Treatment Plan: </b>Using digital scanning, we create a tailored treatment plan that maps out the movement of your teeth.</p>
                                        <p><b>Wearing the Aligners: </b>You'll wear each set of aligners for 20-22 hours daily, changing to a new set every 1-2 weeks.</p>
                                        <p><b>Check-Ups: </b>Regular check-ups help us monitor your progress and ensure everything is going smoothly.</p>
                                        <p><b>Reveal Your New Smile: </b>Once treatment is complete, you'll have a beautifully straight smile!</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>How long do I need to wear clear aligners each day?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You should wear your aligners for 20-22 hours per day, removing them only for meals and oral hygiene routines. Compliance is key to achieving the best results.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>What orthodontic issues can clear aligners treat?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Clear aligners can effectively correct a range of issues, such as crowded teeth, gaps, and bite misalignments. Many patients are suitable candidates for this treatment option.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How often will I need check-ups during aligner treatment?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Regular check-ups are essential to monitor your progress. During these visits, we may also perform cleanings if needed, depending on your oral hygiene levels.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>What brands of clear aligners do you offer?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>We partner with top brands like Invisalign, Straumann ClearCorrect, and Illusion to provide the most effective and comfortable clear aligner treatments.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How do I start my clear aligner journey?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>You can start by scheduling a free virtual consultation with our expert team today. We’ll assess your needs and get you started on the path to the smile you’ve always dreamed of!</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
                <section className="faqs">
                    <div className="container">
                        <div className="faqsheader">
                            <h2>Traditional Braces FAQ’s</h2>
                            <p>Everything you need to know about Traditional Braces service</p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="faqsdata">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>What types of orthodontic treatments do you offer?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>We offer a variety of orthodontic treatments to meet individual patient needs. Whether you're correcting misalignment, closing gaps, or improving your bite, we have several brace systems to help you achieve a confident, healthy smile.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>What are traditional metal braces?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Traditional metal braces are a durable and cost-effective solution for straightening teeth. They offer precise control over tooth movement and are a popular choice for both teenagers and adults. Modern designs are smaller, more comfortable, and can be customized with colored bands.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>What are ceramic braces?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Ceramic braces function similarly to metal braces but are made of clear or tooth-colored materials, making them less noticeable. They are an excellent choice for patients seeking a more aesthetic appearance during their treatment.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>What are self-ligating braces?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Self-ligating braces use a specialized clip instead of elastic bands to hold the wire in place. This results in faster treatment times and fewer components in the mouth. They come in both metal and ceramic options, offering a more comfortable and efficient treatment experience.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>What are lingual braces?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Lingual braces are placed behind the teeth, making them invisible from the front. This is a great option for those looking for a discreet orthodontic solution. They are custom-made for each patient to ensure the best possible fit and results.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>How do I choose the right braces for my treatment?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>During your orthodontic consultation, our specialists will help you select the best treatment option based on your dental needs and aesthetic preferences.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>What can I expect from my orthodontic treatment experience?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Our orthodontic team is committed to offering personalized care using the latest advancements in orthodontics. We aim to provide you with a smooth, comfortable, and effective treatment journey towards achieving your best smile.</p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </section>
            <Footer/>
        </React.Fragment>
    )
}
