import React from "react";
import { Helmet } from "react-helmet";
import "./faqs.css";
import { Link } from "react-router-dom";
import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";
import Accordion from 'react-bootstrap/Accordion';


export default function Faqs() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Faq's</title>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
            </Helmet>
            <Header />
            <section className="breadcrumbarea">
                <div className="container">
                    <div className="breadhead">
                        <h1>FAQ's</h1>
                    </div>
                    <div className="breadcrumblist">
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>FAQ's</li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="faqsp">
                <div className="container">
                    <div className="faqsheader">
                        <h2>FAQ’s</h2>
                        <p>Everything you need to know</p>
                    </div>
                </div>
                <div className="container">
                    <div className="faqsdata">
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>How Can I Schedule My First Appointment?</Accordion.Header>
                                <Accordion.Body>
                                    <p>You can get in touch by filling out the query form on our website at <Link to="/">www.panikarsdentistry.com</Link>, call us at <Link to="tel:+919004366342">9004366342</Link>, or send us an email at <Link to="mailto:panikarsdentistry@gmail.com">panikarsdentistry@gmail.com</Link>.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What Should I Bring to My Initial Visit?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Please bring along your medical history and any details of allergies. If you're experiencing tooth pain, avoid taking pain relievers beforehand, as they may mask the symptoms.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How Often Should I See My Dentist?</Accordion.Header>
                                <Accordion.Body>
                                    <p>It is recommended to visit the dentist twice a year for routine check-ups. If you experience any discomfort like tooth pain, bleeding gums, or sensitivity, it’s important to schedule a visit as soon as possible.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>What Happens During My First Visit?</Accordion.Header>
                                <Accordion.Body>
                                    <p>At Panikar’s Dentistry, we aim to make your first visit pleasant and stress-free. Our welcoming staff will guide you through the process, answer any questions, and ensure you're comfortable throughout your appointment.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>What Services Does Your Clinic Provide?</Accordion.Header>
                                <Accordion.Body>
                                    <p>We offer a wide range of dental services, including general and family dentistry, cosmetic treatments, children’s dentistry, dental implants, and smile makeovers.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Do You Offer Dental Implants?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Yes, we specialize in dental implants, from single-tooth implants to full-arch implant-supported dentures, restoring both function and aesthetics.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Can I Read Patient Testimonials?</Accordion.Header>
                                <Accordion.Body>
                                    <p>We’re proud to have over 150 5-star reviews from our satisfied patients. You can check out their feedback and experiences on our website.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>What If I Have Questions Before My Consultation?</Accordion.Header>
                                <Accordion.Body>
                                    <p>Feel free to reach out to us with any questions. You can call us at <Link to="tel:+919004366342">9004366342</Link> or fill out our contact form, and our friendly staff will be happy to assist you.</p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </section>
            <Footer />
        </React.Fragment>
    )
}